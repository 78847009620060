.configMFA .component-card {
    display:         flex;
    align-items:     center;
    justify-content: center;
}

.configMFA .steps-box {
    background-color: #efefef;
    width:              700px;
    max-height:         450px;
    /* height:             450px; */
    border:             1px solid #dbdbdb;
    border-radius:      10px;
    margin:             auto;
    padding:            35px;
    box-shadow:         0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.configMFA .steps-box > div {
    position:           relative;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    space-evenly;
    height:             100%;
    grid-gap:           30px;
    gap:                30px;
    text-align:         center;
    font-weight:        700;
    font-size:          16px;
}

.configMFA .steps-box .second-step-buttons,
.configMFA .steps-box .third-step-buttons {
    width:           50%;
    display:         flex;
    justify-content: space-around;
}

.qrCodeClass {
    position:           relative;
}
.configMFA .steps-box .showQrLabel {
    position: absolute;
    bottom:   -23px;
    left:     12px;
}

.configMFA .steps-box .second-step .secretCodeLabel {
    color: #479bfb;
    font-size: 11px;
}

.configMFA .steps-box .third-step input {
    padding: 15px;
    font-size: 24px;
    text-align: center;
    width: 140px;
    border-width: 2px;
}

.form-mfa-code{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
}