.forgotPassword{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgotPassword {
    height:                 100%;
    padding:                0;
    margin:                 0;
    display:                flex;
    align-items:            center;
    justify-content:        center;
}

.forgotPassword .forgotPassword-container {

    max-width:              580px;
    margin:                 0 auto;
    animation-fill-mode:    none !important;
    animation-duration:     .3s;
    text-align:             center;
    font-size:              14px;
    overflow:               hidden;
    box-sizing:             border-box;

    /* stili standard forgotPassword systrategy */
    display:                flex;
    position:               absolute;
    top:                    50%;
    left:                   50%;
    transform:              translate(-50%, -50%);
    height:                 400px;
    width:                  580px;
    border-radius:          6px;
    box-shadow:             -1px 1px 5px 1px rgb(0 0 0 / 15%);
    background:             white;

}

.forgotPassword .forgotPassword-outer-container {
    padding:                10px 25px;
    text-align:             left;
}

.forgotPassword .forgotPassword-outer-container .form-group{
    width:                  100%;
    margin-top:             15px;
}

.logotext {
    margin-left: 0;
    margin-top: 45px;
    width: 40%;
}

.login-media{
    font-family: 'Prosto One', cursive;
    font-weight: 600;
    color: #202a69;
}
.login-anal{
    font-family: 'Prosto One', cursive;
    font-weight: 600;
    color: #1268be;
}

.app-login-header{
    font-size: 2em;
    padding: 25px 0px 25px 26px;
    text-align: left;
}

.forgotPassword-container .only-logo{
    background-color: #D0EFFD;
}

.proddisc .forgotPassword-container .only-logo { 
    background-color: var(--color-light) !important;
}

.environment{
    display:                none;
    text-align:             center;
    margin:                 0;
    color:                  black;
    top:                    44px;
    letter-spacing:         2px;
    user-select:            none;
}

h1.environment {
    top:                    15px !important;
}

.environment.cliente-cod,
.environment.cliente-desc {
    top:                    62px !important;
}

.forgotPassword-container .side {
    position:               relative;
}
.forgotPassword-container .side.left {
    height:                 100%;
    width:                  360px;
    border-top-left-radius:     5px;
    border-bottom-left-radius:  5px;
}
.forgotPassword-container .side.right {
    height:                     100%;
    width:                      220px;
    border-top-right-radius:    5px;
    border-bottom-right-radius: 5px;
    position:                   relative;
    align-items:                center !important;
    justify-content:            center !important;
    box-sizing:                 border-box;
    order:                      2;

}

.forgotPassword-container .top-bottom-section {
    height:                 110px;
}

.forgotPassword-container .header-product-name {
    position:               relative;
}
.forgotPassword-container .header-product-name > div {
    position:               relative;
}

.forgotPassword-container .header-product-name .ms-info-forgotPassword {
    position:               relative;
    margin-left:            auto;
    margin-right:           auto;
}

.forgotPassword-container .user-psw-container {
    position:               relative;
    margin:                 0 auto;
    border:                 0;
    width:                  85%;
}

.forgotPassword-container .user-psw-container .logo.systrategy {
    width:                  150px;
}

.forgotPassword-container .user-psw-container .error {
    position:               absolute;
    top:                    145px;
    left:                   0;
}

.forgotPassword-container .footer-info {
    display:                flex;
    position:               absolute;
    width:                  85%;
    bottom:                 0;
    left:                   0;
    flex-direction:         row;
    align-items:            flex-end;
    justify-content:        space-between;
    max-height:             35px;
}

.forgotPassword-container .footer-info a,
.forgotPassword-container .footer-info a img {
    height:                 35px;
}

.user-psw-container input {
    padding-left:           5px;
}

.user-psw-container input[type="submit"] {
    font-weight:            bold;
    background-color:       white;
    cursor:                 pointer;
    text-align:             center;
    padding-left:           0;
    height:                 40px;
    border-radius:          4px;
}
.user-psw-container input::placeholder {
    color:                  #a9a9a9;
}

.forgotPassword .forgotPassword-content {
    padding:                0;
    width:                  60%;
    order:                  1;
}

.forgotPassword .forgotPassword-outer-container {
    padding:                10px 25px;
    text-align:             left;
}

.forgotPassword .forgotPassword-outer-container .form-group{
    width:                  100%;
    margin-top:             15px;
}

.forgotPassword .forgotPassword-outer-container .forgotPassword-credentials-wrapper {
    margin-top:             20px;
    margin-bottom:          3px;
    width:                  100%;
}

.forgotPassword .forgotPassword-outer-container .forgotPassword-credentials-wrapper {
    margin-top:             0px;
    margin-bottom:          10px;
    width:                  100%;
}

.forgotPassword-outer-container .forgotPassword-credentials-wrapper.top {
    margin-top:             25px !important;
    margin-bottom:          3px  !important;
    width:                  100% !important;
}

.forgotPassword-credentials-wrapper .icon {
    /* font-size:  calc(20px * var(--multiplier-icon)) !important; */
    font-size:  20px !important;
}

.forgotPassword-credentials-wrapper input::placeholder {
    color:      black;
}

.forgotPassword-credentials-wrapper .MuiInput-underline:after {
    border-bottom: 2px solid var(--color-dark);
}

.forgotPassword-credentials-wrapper .MuiInput-underline.Mui-error:after {
    border-bottom: 2px solid var(--color-red)!important;
}

.forgotPassword .forgot-credentials-link {
    margin-top:             20px;
    width:                  100%;
    text-align:             left;
    color:                  var(--color-lightgrey);
    font-size:              14px;
    display:                flex;
    align-items:            baseline;
}

.forgotPassword .forgot-credentials-link i.icon::before {
    font-size:              14px;
}

.forgotPassword .forgot-credentials-link:hover {
    /* text-decoration: underline; */
    color:           var(--color-darkgrey);
    cursor:          pointer;
}

.forgotPassword .forgot-credentials-link:hover span{
    text-decoration: underline;
}

.forgotPassword .forgotPassword-btn {
    margin-top:             18px;
    margin-bottom:          10px;
    font-size:              16px;
    width:                  100%;
    border:                 1px solid #000;
    color:                  #000;
    background-color:       #ffffff;
}

.forgotPassword .forgotPassword-btn:disabled {
    color: rgba(0, 0, 0, 0.26)!important;
    border: 1px solid rgba(0, 0, 0, 0.12)!important;
}

.forgotPassword .forgotPassword-btn:hover {
    background-color:   var(--color-button)!important;
    border-color:       var(--color-dark)!important;
}


@media screen and (max-width: 575px) {

    .forgotPassword .forgotPassword-content {
        width:              100%;
        order:              2;
    }

    .forgotPassword .green {
        width:              100%;
    }

    .forgotPassword  .logo-content {
        width:              100%;
        order:              1;
    }
}

.forgotPassword .database-info {
    font-size:              14px !important;
    color:                  #2d2d2d !important;
    position:               relative;
    top:                    2px;
}


.forgotPassword .version-number > span {
    font-size:              12px !important;
}

.forgotPassword .info-top-bottom {
    height:                 50px;
    width:                  100%;
}

.forgotPassword .info-top {
    position:               absolute;
    top:                    30px;
}

.forgotPassword .info-bottom {
    position:               absolute;
    bottom:                 0;
}

.forgotPassword .info-bottom > div > span {
    display:                block;
}

.forgotPassword .side.right.only-logo .logo-lg {
    width: 185px;
    /* margin: 30px 0 40px 0; */
    position:               absolute;
    top:                    50%;
    left:                   50%;
    transform:              translate(-50%, -50%);
    height: 100%;
}

.forgotPassword .systrategy-logo:hover {
    filter:
                            drop-shadow(-1px -1px 1px rgba( 130,142,193,0.2) )
                            drop-shadow( 1px -1px 1px rgba( 130,142,193,0.2) )
                            drop-shadow(-1px  1px 1px rgba( 130,142,193,0.2) )
                            drop-shadow( 1px  1px 1px rgba( 130,142,193,0.2) )
    ;
}

.flex-box-between{
    display: flex !important;
    justify-content: space-between !important;
}

.align-mfa-center{
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
}

.forgotPassword .button-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.forgotPassword .forgot-password-label{
    color: #1168be;
    cursor: pointer;
}

.forgot-password-label:hover{
    text-decoration: underline;
}

.forgotPassword .forgot-extra-btns{
    display: flex;
    justify-content: space-between;
    width: 100%;
}