.report-andamento .filters-section{
    display: flex;
    align-items: center;
}

.report-andamento .filters-section .input-anno input {
    padding: 0;
}

.report-andamento .filters-section .wrapper-checks{
    display: flex;
    flex-direction: row;
    gap: 15px
}

.report-andamento .report-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
}
