.change-password .component-card{
    display: flex;
    flex-direction: column;
}

.change-password .actions-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.change-password .infos-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 50px;
    width: 500px;
}

/*
.change-password
.change-password 
.change-password 
.change-password 
.change-password 
.change-password 
.change-password */