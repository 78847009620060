.middlePage-Loader{
    /* position:fixed;
    top: 50%;
    left: 50%; */
}

.circularProgress{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 1;
}

.circularProgress span{
    width: 40px !important;
    height: 40px !important;
    /* color: var(--color-main) !important; */
    color: #1976d2 !important;
}