.report-dn .filters-section{
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 10px;
}

.report-dn .report-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
}