.loading-background{
  position: fixed;
  height: 93.1%;
  width: 95.3%;
  background-color: #ababab76;
  z-index: 999;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.loading-background-dialog{
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #ababab76;
  z-index: 999;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.app-main{
  height: 100%;
}

.app-main-logged{
  background-color: #e4f0f8;
}

.main-background{
  height: 100%;
  display: flex;
  background-color: #e4f0f8;
}

.font-12{
  font-size: 0.75rem !important;
}

.center-text input{
 text-align: center !important;
}

.center-text{
  text-align: center;
}

aside ul li,
.cursor-pointer {
    cursor: pointer!important;
}

.cursor-defaul {
    cursor: default!important;
}

/* ----- STILI PER BACKGROUND STANDARD SYSTRATEGY ----- */
.background-with-logo {
  display:                block;
  position:               fixed;
  top:                    -76%;
  left:                   -100%;
  z-index:                -1;
  height:                 250%;
  width:                  250%;
  background-image:        url('assets/svgs/systrategy_cube_logo.svg');
  background-repeat:      no-repeat;
  background-size:        contain;
  background-position:    center;
  background-origin:      content-box;
  filter:                 opacity(20%) grayscale(0%) saturate(100%) blur(3px);
}
.background-with-logo-top-dx {
  display:                block;
  position:               fixed;
  top:                    -61%;
  left:                   -5%;
  z-index:                -1;
  height:                 100vh;
  width:                  100vw;
  background-image:       url('assets/svgs/systrategy_cube_logo_top_dx.svg');
  background-repeat:      no-repeat;
  background-size:        contain;
  background-position:    center;
  background-origin:      content-box;
  filter:                 opacity(15%) grayscale(0%) saturate(100%) blur(3px);
}
.background-with-logo-top-dx-bis {
  display:                block;
  position:               fixed;
  top:                    -82%;
  left:                   -8%;
  z-index:                -1;
  height:                 115vh;
  width:                  115vw;
  background-image:       url('assets/svgs/systrategy_cube_logo_top_dx.svg');
  background-repeat:      no-repeat;
  background-size:        contain;
  background-position:    center;
  background-origin:      content-box;
  filter:                 opacity(15%) grayscale(0%) saturate(100%) blur(3px);
}
.background-with-logo-bot-dx {
  display:                block;
  position:               fixed;
  top:                    60%;
  left:                   -5%;
  z-index:                -1;
  height:                 100vh;
  width:                  100vw;
  background-image:       url('assets/svgs/systrategy_cube_logo_bot_dx.svg');
  background-repeat:      no-repeat;
  background-size:        contain;
  background-position:    center;
  background-origin:      content-box;
  filter:                 opacity(10%) grayscale(0%) saturate(100%) blur(3px);
}
.background-with-logo-bot-dx-bis {
  display:                block;
  position:               fixed;
  top:                    67%;
  left:                   -8%;
  z-index:                -1;
  height:                 115vh;
  width:                  115vw;
  background-image:       url('assets/svgs/systrategy_cube_logo_bot_dx.svg');
  background-repeat:      no-repeat;
  background-size:        contain;
  background-position:    center;
  background-origin:      content-box;
  filter:                 opacity(10%) grayscale(0%) saturate(100%) blur(3px);
}

.button-contained {
    background-image: linear-gradient(90deg,#006ae3,#479bfb,#006ae3,#479bfb);
    background-size: 300% 100%;
    border: 1px solid transparent;
}
.button-contained:hover {
    transition: all .5s ease;
    background-position: 100% 100%;
}
.button-contained:not(:hover) {
    transition: all .5s ease;
    background-position: 10% 100%;
}
button:disabled {
    cursor: not-allowed!important;
    opacity: .32;
    background: #dde1f1;
    border: 1px solid #5c648d;
    color: #5c648d!important;
}

.input-container .input,
.input-container select {
    border:        2px solid #dde1f1;
    color:        #27273f;
    border-radius: 4px;
}

button.Mui-disabled{
  border: none
}

.flexed{
  display: flex;
}

.flexed-vert{
  display: flex;
  flex-direction: column;
}

.flexed-center{
  display: flex;
  justify-content: center;
}

.flexed-end{
  display: flex;
  align-items: flex-end;
}

.relative{
  position: relative;
}