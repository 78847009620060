/************************ Dettaglio talent ************************/

.dettaglio-talent .component-card{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dettaglio-talent .actions-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dettaglio-talent .infos-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 50px;
}

.dettaglio-talent .flex-row{
    display: flex;
    flex-direction: row;
    gap: 55px;
    width: 100%;
}

.dettaglio-talent .flex-row>div{
    width: 300px;
}

.dettaglio-talent .checkboxes-wrapper{
    display: flex;
    justify-content: space-between;
}

.dettaglio-talent .wrapper-actions{
    display: flex;
    gap: 30px;
}

.dettaglio-talent .fixed-height-textField div {
    height: 39px;
}

.dettaglio-talent .fixed-height-autocomplete div div{
    height: 39px;
}

.dettaglio-talent .flex-details-wrap{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.dettaglio-talent .flex-rows-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dettaglio-talent .dataGrid{
    max-width: 500px;
}

/************************ Dialog dettaglio talent ************************/

.dialog-ins-target .flex-dialog-textfield{
    display: flex;
    gap: 20px;
}

.dialog-ins-target .loading-background-dialog{
    left: 0;
}