.report-fatturato .filters-section{
    display: flex;
    align-items: center;
    gap: 10px
}

.report-fatturato .report-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
}