/************************ Testata Proposta ************************/

.proposta-testata .component-card{
    display                          : flex;
    flex-direction                   : column;
    gap                              : 30px;
}

.proposta-testata .actions-wrapper{
    display                          : flex;
    flex-direction                   : row;
    justify-content                  : flex-end;
}

.proposta-testata .infos-wrapper{
    display                          : flex;
    flex-direction                   : column;
    gap                              : 25px;
    padding-left                     : 50px;
    width: 100%;
}

.proposta-testata .flex-row,
.proposta-logs .flex-row{
    display                          : flex;
    flex-direction                   : row;
    gap                              : 45px;
    width                            : 100%;
    flex-wrap                        : wrap;
    margin-bottom                    : 10px;
}

.proposta-testata .flex-roww{
    display                          : flex;
    flex-direction                   : row;
    gap                              : 45px;
    width                            : 100%;
    flex-wrap                        : wrap;
    margin-bottom                    : 10px;
}

.proposta-testata .flex-row>div>div,
.proposta-logs .flex-row>div>div{
    width                            : 300px;
}

.proposta-testata .wrapper-actions{
    display                          : flex;
    gap                              : 30px;
}

.add-entry-dialog [role="dialog"]{
    max-width: none;
}

.add-entry-dialog .flex-row{
    display: flex;
    flex-direction: row;
    gap: 55px;
    width: 100%;
}

.add-entry-dialog .flex-row>div>div {
    width: 300px;
}

.flex-row>.smaller-date-field>div {
    width: 165px !important;
}

.id-proposta>div{
    width: 90px !important;
}

.add-entry-dialog .infos-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.proposta-testata{
    display: flex;
    height: 100%;
}

.proposta-testata .details-area{
    width: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.proposta-testata .testata-wrapper{
    display: flex;
}

.proposta-testata .notes-area{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.proposta-testata .notes-area span {
    height: 50%;
}

.proposta-testata textarea{
    resize: none;
}

.proposta-testata .add-autocomplete{
    position: relative;
}

.proposta-testata .add-autocomplete .add-btn{
    position: absolute;
    right: -35px;
}

.proposta-testata .add-autocomplete .copy-btn{
    position: absolute;
    left: -35px;
}

.popper-box{
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.proposal-date-range-container{
    width: 300px;
    display: flex;
    gap: 10px;
}