.report-talents .filters-section{
    display: flex;
    align-items: center;
}

.report-talents .report-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.report-talents.dettaglio .back-button{
    margin-right: 15px;
}

.report-talents .bg-clickable{
    background-color: rgb(244, 240, 251);
}