/************************ Dettaglio agenzia ************************/

.dettaglio-agenzia .component-card{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dettaglio-agenzia .actions-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dettaglio-agenzia .infos-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 50px;
}

.dettaglio-agenzia .flex-row{
    display: flex;
    flex-direction: row;
    gap: 55px;
    width: 100%;
}

.dettaglio-agenzia .flex-row>div>div{
    width: 300px;
}

.dettaglio-agenzia .wrapper-actions{
    display: flex;
    gap: 30px;
}