.header {
    background-color: #fff;
    color: black;
    min-height: 36px;
    padding: 0 40px;
    position: fixed;
    width: calc( 100vw - 135px );
    height: 0;
    left: 55px;
    z-index: 1000;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 19%);
}

.header-config-mfa {
    background-color: #8fd2f0;
    color: black;
    min-height: 36px;
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 1000;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 19%);
}

.header-config-mfa .header-container {
    padding: 0 40px;
}

.header-container{
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.header-container-tools{
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    gap: 15px;
}

.side-menu-options,
.side-menu-logo {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 4px;
}

.side-menu-options:hover {
    background-color: rgb(237, 231, 246) !important;
}

.last-voice-selected{
    background-color: rgb(224, 209, 247) !important;
}

.side-menu-options div {
    width: 178px;
    /* background-color: darkseagreen; */
    padding: 7px;
    border-radius: 4px;
}

.user-settings-wrapper {
    display:        flex;
    align-items:    stretch;
    cursor:         pointer;
    box-shadow: inset 0px 0px 3px 1px #cbcbcb;
    border-radius:  5px;
    padding:        5px 7px;
    background-image: linear-gradient(90deg,#dcedff,white,#dcedff,white);
    background-size: 300% 100%;
    align-items: flex-end;
}
.user-settings-wrapper:hover:hover {
    transition: all .5s ease;
    background-position: 100% 100%;
}
.user-settings-wrapper:not(:hover) {
    transition: all .5s ease;
    background-position: 10% 100%;
}
.user-settings-wrapper i{
    padding-left: 4px;
}
.user-settings-wrapper i.icon::before {
    font-size: 20px;
}

.user-settings{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-left: 10px;
}

.app-name{
    font-size: 20px;
}

.main-content-mfa{
    display: flex;
    /* flex-direction: column; */
    width: 100%;
}

.main-content{
    display: flex;
    width: 100%;
}

aside{
    width: 60px;
    background-color: #fff;
    color: #ffffff;
    text-align: left;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    height: 100%;
    transition: all 0.3s;
    z-index: 10000;
    box-shadow: 4px 0 4px 0 rgb(0 0 0 / 20%), 6px 0 10px 0 rgb(0 0 0 / 19%);
    user-select: none;
}

.logo-and-menu-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

aside:hover{
    width:          250px;
}

aside ul{
    position: absolute;
    padding: 20px 0 20px 5px;
    top: 82px;
    overflow: auto;
    height: calc(100vh - 200px);
}

aside ul li{
    list-style: none;
    margin-bottom: 30px;
    margin-left: 5px;
    transition: color 0.3s
}

aside ul a{
    color: rgb(54, 65, 82);
    text-decoration: none;
}

aside ul a.selected{
    color: rgb(94, 53, 177);
}


aside li.flex-menu-block{
    display: flex; 
    flex-direction: column;
    gap: 3px;
}

.dropdown-user-settings{
    width: 226px;
    background: white;
    height: 100%;
    z-index: 11;
    border-radius: 15px;
    text-align: left!important;
}

aside:hover .logo-container{
    width: 100%;
    transition: all 0.3s;
}

.logo-container{
    cursor: pointer;
    display: flex;
    width: 170px;
    align-items: center;
    transition: all 0.3s;
}

.logo-container:hover svg {
    filter:
            drop-shadow(-1px -1px 1px #ffffffb3 )
            drop-shadow( 1px -1px 1px #ffffffb3 )
            drop-shadow(-1px  1px 1px #ffffffb3 )
            drop-shadow( 1px  1px 1px #ffffffb3 )
            !important
    ;
}

.logo-wrapper{
    width: 100%;
    position: relative;
}

.logo-wrapper svg:nth-of-type(1){
    position: inherit;
    /* width: 135px; */
    /* filter: drop-shadow(0px 0px 1px #524f4f); */
    z-index: 10000;
}
.logo-wrapper svg:nth-of-type(2){
    /* width: 135px; */
    filter:     brightness(100);
    position:   absolute;
    top:        1px;
    left:       1px;
    z-index:    100;
}
.logo-wrapper svg:nth-of-type(3){
    /* width: 135px; */
    filter:     brightness(100);
    position:   absolute;
    z-index:    100;
    bottom:     3px;
    right:      -1px;
}

.logout-button-container.dropdown-user-options {
    background-image: linear-gradient(90deg,#dcedff,white,#dcedff,white);
    background-size: 300% 100%;
    color:            #4d4d4d;
    border-radius: 5px;
    padding:            2px 18px;
    font-size:          0.75rem;
    box-shadow: inset 0px 0px 3px 1px #777777;
}
.logout-button-container.dropdown-user-options:hover:hover {
    transition: all .5s ease;
    background-position: 100% 100%;
}
.logout-button-container.dropdown-user-options:not(:hover) {
    transition: all .5s ease;
    background-position: 10% 100%;
}


.dropdown-user-settings .dropdown-user-options{
    background-color: #ffffff;
    transition:         background-color 0.3s;
    color:            #4d4d4d;
    justify-content:    flex-start;
    padding-left:       17px;
    width:              100%;
    height:             50px;
}

.dropdown-user-options:hover{
    background-color: #ddd;
}

.font-little{
    font-size: 12px !important
}

.logoRoobeek {
    width:  24px;
    height: 24px;
}

.roobeekPath {
    color:          #ffffff;
    text-decoration:  none;
}

.roobeekPath:hover {
    color: #8fd2f0 !important;
}

.main-content .component-container{
    /* margin-left: 55px;
    margin-left: 58px; */
}

.component-container{
    display: flex;
    width: 100%;
    padding: 50px 15px 15px 75px;
    overflow-x: auto;
}

.component-container.configMFA{
    padding: 0 !important;
}

.component-container .component-card{
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    height: calc(100% - 30px);
    padding: 15px;
    overflow-y: auto;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.component-container .component-options-wrapper{
    border-radius: 5px;
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-align: start;
    width: fit-content;
}

.TextFieldDate p {
    position: inherit;
    right:    110px;
    width:    550px!important;
}

aside i.icon::before {
    font-size: 20px;
}

.link:hover i.icon::before,
.myShadow:hover i.icon::before {
    filter: drop-shadow(0px 0px 4px var(--color-shadow));
    cursor: pointer;
}

.dianButton:hover {
    background-color:   var(--color-button)!important;
    border-color:       var(--color-dark)!important;
}

.dianButton .myIcon::before {
    font-size: 1.5rem!important;
}

.circularprogressWrapper {
    min-width: 37px;
    padding-top: 5px;
}

.circularprogressWrapper span,
.circularprogressWrapper svg {
    width: 25px !important;
    height: 25px !important;
    margin-right: 0.5vw;
    color: var(--color-dark) !important;
}

.myFlex {
    display:            flex;
    align-items:        center;
    justify-content:    center;
    flex-direction:     column;
    min-height:         98px;
    height:             100%;
}

.hiddenButton {
    visibility: hidden;
    min-width: 24px;
}

.smallSpace {
    margin-right: 10px !important;
}

.mediumSpace {
    margin-right: 25px !important;
}

.bigSpace {
    margin-right: 3rem !important;
}

::-webkit-scrollbar{
    width:0.6em;
    height:0.6em;
    background: #ddd;
    margin-right: 0px!important;
}

::-webkit-scrollbar:hover{
    background-color:#ccc;
    border: none;
}

::-webkit-resizer{
    -webkit-border-radius:4px;
    background-color: #ccc;
    border: none;
}

::-webkit-scrollbar-thumb{
    min-height:0.6em;
    min-width:0.6em;
    -webkit-border-radius:4px;
    background-color: #bbb;
    border: none;
}

::-webkit-scrollbar-thumb:hover{
    background-color: #999;
    border: none;
}

::-webkit-scrollbar-thumb:active{
    background-color: #999;
    border: none;
}


.page-name {
    text-transform: capitalize;
}

aside:hover .logo-and-menu-container .side-menu-logo > img {
    max-height:     80px;
    height:         80px;
    transition: all 0.3s;
    width: 100%;
    margin-top:         10px;
}

.logo-and-menu-container .side-menu-logo > img {
    margin-top:         0px;
    max-height:         40px;
    height:             40px;
    transition:         all 0.3s;
    z-index:            100001;
    width: 100%;
    cursor: pointer;
}

.logo-and-menu-container .side-menu-logo > img.logoImage{
    filter:
        drop-shadow(-1px -1px 0.5px #ffffff2c )
        drop-shadow( 1px -1px 0.5px #ffffff2c )
        drop-shadow(-1px  1px 0.5px #ffffff2c )
        drop-shadow( 1px  1px 0.5px #ffffff2c )
    ;
}

.font-14{
    font-size: 14px;
}

.font-16{
    font-size: 16px;
}

.font-20{
    font-size: 20px;
}

.font-22{
    font-size: 22px;
}

.font-24{
    font-size: 24px !important;
}
/* Data Grid */

.dataGrid{
    border: none !important;
    font-size: "0.830rem !important";
    height: 100%;
    box-shadow: 0 0 0 1px rgba(224, 224, 224, 1);
}

/* .dataGrid .MuiDataGrid-cell--editable {
    background-color: '#ecf3f0 !important'
} */

.dataGrid .noRowsRoot{
    flex-direction: column;
}

.dataGrid .noRowsRoot .ant-empty-img-1{    
    fill: #aeb8c2
}
.dataGrid .noRowsRoot .ant-empty-img-2{    
    fill: #f5f5f7
}
.dataGrid .noRowsRoot .ant-empty-img-3{    
    fill: #dce0e6
}
.dataGrid .noRowsRoot .ant-empty-img-4{    
    fill: #fff
}
.dataGrid .noRowsRoot .ant-empty-img-5{    
    fill: #f5f5f5;
    fill-opacity: 0.08
}

/* fine Data Grid */

.dates-section{
    margin-top: 40px;
}

.percentage-text-field{
    width: 85px;
}

.percentage-text-field-dn{
    width: 65px;
}

.percentage-text-field-margine{
    width: 125px;
}

.flexed-gap{
    display: flex;
    gap: 10px;
}

.no-pad{
    padding: 0 !important;
}

.no-margin{
    margin: 0 !important;
}

button.remove-option-button-select{
    margin-right: 25px;
    opacity: 0;
}

.select-remove-button-option:hover button.remove-option-button-select{
    opacity: 1;
}

.table-circular-progress{
    width: 20px !important;
    height: 20px !important;
    cursor: default;
}

.full-width{
    width: 100%;
}

.small-gap{
    gap: 20px;
}

.pad-left{
    padding-left: 5px;
}

.pad-right{
    padding-right: 5px;
}

.collapsed .collapsed-element{
    height: 21px;
    color: rgb(54, 65, 82) !important;
    padding-left: 50px;
    font-size: 14px !important;
}

.adjust-proposal-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.anchor-custom{
    color: rgb(54, 65, 82);
    text-decoration: none;
}

.full-height{
    height: 100%;
}

.resized-pad-text-field{
    padding: 10px 14px !important;
}

.red-txt{
    color: rgb(156, 15, 15);
}

.flex-box-between.dn-mod{
    padding: 16px !important;
    display: flex !important;
    justify-content: space-between !important;
}

/* Notifiche dialog */

.notifications-dialog .MuiDialog-container [role="dialog"]{
    max-width: 600px !important;
    width: 600px !important;
}

.notifications-dialog .notifications-dialog-wrapper{
    max-height: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 10px 0 !important;
}

.notifications-dialog .notifications-dialog-wrapper .notifications-dialog-container{
    width: 90%;
    height: 100%;
}

.notifications-dialog .notifications-dialog-wrapper .notifications-row{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.notifications-dialog .notifications-dialog-wrapper .notifications-row>div{
    display: flex;
    align-items: center;
    width: 90%;
}

.notifications-dialog .notifications-dialog-wrapper .notifications-row>div .kProposta{
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.notifications-dialog .notifications-dialog-wrapper .notifications-row>div .oramin{
    width: 90px;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    text-align: center;
}

/* .yellow-dot-notification{
    position: absolute;
    background-color: rgb(230, 173, 0);
    border-radius: 50%;
    width: 8px;
    height: 8px;
    right: 3px;
    top: 5px;
    animation-name: notifica;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-direction: alternate;
    animation-iteration-count: infinite;
} 

@keyframes notifica {
    from {background-color: rgb(230, 173, 0)}
    to {background-color: rgb(245, 231, 191)}
} */

.yellow-bell-notification{
    animation-name: notifica;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    font-size: 20px;
} 

@keyframes notifica {
    from {color: rgb(230, 173, 0)}
    to {color: rgb(245, 231, 191)}
}