/************************ Dettaglio cliente ************************/

.dettaglio-cliente .component-card{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dettaglio-cliente .actions-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dettaglio-cliente .infos-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 50px;
}

.dettaglio-cliente .flex-row{
    display: flex;
    flex-direction: row;
    gap: 55px;
    width: 100%;
}

.dettaglio-cliente .flex-row>div{
    width: 300px;
}

.dettaglio-cliente .wrapper-actions{
    display: flex;
    gap: 30px;
}

/* .dettaglio-cliente .align-textifieldBypass-center {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: flex-start;
} */

.dettaglio-cliente .add-element-chip{
    display: flex;
    flex-direction: column;
    width: 40px;
    background-color: rgb(244, 244, 244);
    color: #1876d2;
}

.dettaglio-cliente .add-element-chip:hover{
    background-color: rgb(209, 209, 209);
    color: rgba(0, 0, 0, 0.87);
}