.proposte-wrapper .corpo-proposta-container{
    display: flex;
    flex-direction: row;
    height: 100%;
}

.corpo-proposta-container .configuration-block{
    width: 30%;
    height: 100%;
    box-shadow: 0 0 0 1px rgba(224, 224, 224, 1);
    border-radius: 4px;
    position: relative;
}

.proposte-wrapper .grids-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 70%;
}

.proposte-wrapper .configuration-block{
    display: flex;
    flex-direction: column;
}

.proposte-wrapper .parameters{
    padding: 5px 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    outline: 1px solid #ddd;
}

.proposte-wrapper .riepilogo{
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.proposte-wrapper .add-new-row{
    font-size: 14px;
}

.proposte-wrapper .tabs{
    height: 25px;
    min-height: 20px;
}

.proposte-wrapper .tabs button{
    height: 20px;
    min-height: 20px;
}

.proposte-wrapper .react-split__pane{
    overflow: unset;
}

.proposte-wrapper .nuovaProposta-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pianoFatturazione-container{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.corpo-proposta-container .notes-textarea{
    resize: none;
    width: 80%;
    /* height: 100%; */
    margin-bottom: 10px;
}

.corpo-proposta-container .characters-left{
    position: absolute;
    color: #aaaaaa;
    right: 10%;
    bottom: 15px;
}

.grids-container {
    box-shadow: 0 0 0 1px rgba(224, 224, 224, 1);
    border-radius: 4px;
}

.corpo-proposta-container .box-handler {
    display:         flex;
    justify-content: center;
    gap: 10px;
    align-items: baseline;
    background-color: #eee;
}

.corpo-proposta-container .box-toggler {
    /* border: 1px solid;
    border-radius: 4px; */
    padding: 2px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    user-select: none;
    z-index: 5;
    /* background-color: #fff; */
}

/* .corpo-proposta-container .react-split__pane .dataGrid{
    padding-bottom: 20px !important;
}

.react-split.react-split--horizontal > div:nth-last-child(-n+1) .dataGrid{
    padding-bottom: 0 !important;
} */

.corpo-proposta-container .options-menu{
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    /* border: 1px solid; */
    border-radius: 50%;
    padding: 5px;
    width: 20px;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.4s;
}

.corpo-proposta-container .options-menu:active{
    transition: all 0.4s;
    background-color: #ccc;
}

.corpo-proposta-container .options-menu:hover{
    transition: all 0.4s;
    background-color: #eee;
}

.tabs .Mui-disabled{
    background-color: #fff;
}

.corpo-proposta-container .calculated-fields{
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
   /*  height: 75%; */
   height: 100%;
}

.corpo-proposta-container .textarea-save-note{
    right: 2px;
    position: absolute;
}

.corpo-proposta-container .textarea-zone{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 25%; */
}

.dialogueTitleSovrascriviDn{
    display: flex;
    justify-content: space-between;
}

.nuovaProposta-wrapper .back-proposte-lista{
    font-size: 16px !important;
    margin-right: 10px !important;
}

.nuovaProposta-wrapper .header-proposte{
    display: flex;
    align-items: center;
}

textarea{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
}

.pianoFatturazione-container .row-residuo{
    margin: 10px 0px;
    display: flex;
    gap: 10px;
    justify-content: space-between
}

.pianoFatturazione-container .row-residuo .box-residuo,
.pianoFatturazione-container .row-residuo .box-conferma{
    display: flex;
    gap: 10px;
}

.dataGrid .non-editable-cell{
    background-color: #eee;
}

.dataGrid .non-editable-row{
    background-color: #eee !important;
    font-weight: bold;
}

.proposte-wrapper .header-wrapper-proposta{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.proposte-wrapper .header-wrapper-proposta .status-management{
    display: flex;
    align-items: flex-end;
}

.dataGrid .red-row{
    background-color: rgb(255, 202, 202);
}

.proposta-logs .logs-wrapper{
    padding-left: 50px;
}

.totale-ricavi-bg{
    background-color: rgb(244, 240, 251) !important;
}

.costi-extra-cuscinetto-bg{
    background-color: rgb(240, 246, 251) !important;
}

.proposte .filters-row{
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    justify-content: space-between;
}

.proposte .filters-row .wrapped-filters{
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
}

.proposte .lista-proposte-container{
    height: calc(100% - 66px);
}

.dialog-extra-filters-body{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 35px !important;
}

.dialog-extra-filters-body .filter-block{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
}

.corpo-proposta-container .yellow-icon{
    color: rgb(245, 211, 44);
}