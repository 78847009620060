.dashboard .component-card{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    overflow: hidden;
}

.dashboard .graph-wrapper{

}

.dashboard .graph-wrapper:nth-of-type(odd){
    /* display: flex;
    height: 500px;
    width: 300px;
    /*height: 100%;*/
    padding-right: 10px;
}

.dashboard .with-margz .margz-wrap{
    display: flex;
    justify-content: space-evenly;
}

.dashboard .with-margz .margz-wrap .margz-values{
    display: flex;
    gap: 10px;
}

.dashboard .with-margz .margz-wrap .margz-values>div>div{
    width: 250px;
}

.first-graph-stacked-cols{
    display: flex;
    flex-direction: column;
}

.first-graph-stacked-cols .first-graph-stacked-cols-element{
    display: flex;
    align-items: center;
}

.third-graph-wrapper{
    height: 93%;
    width: 100%;
}

.dashboard .graph-box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}