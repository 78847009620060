.side-menu-options .icon {
    display: flex;
    /* background-color: darkseagreen; */
    width: 25px;
    height: 21px;
    padding: 7px 4px 7px 7px;
    justify-content: center;
    border-radius: 4px;
    margin-right: 3px;
    align-items: center;
}

.icon.textGreen {
    color:  var(--color-green)!important;
}

.icon.textRed {
    color:  var(--color-red)!important;
}